<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field type="text" hide-details filled
                          label="Квартира" disabled v-model="flat_name" color="grey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="date_start" :class_="date_start ? '' : 'req-star'" req label="Дата початку"/>
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="date_end" req :class_="date_end ? '' : 'req-star'" label="Дата закінчення"/>
          </v-col>
          <v-col cols="12">
            <v-select filled v-model="absent_reason" :items="absentReasonSelect"
                      hide-details color="grey"
                      label="Причина відустності" :class="absent_reason ? '' : 'req-star'"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="К-сть відсутніх" v-model="persons"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="persons ? '' : 'req-star'"
                          color="grey"
                          v-decimal
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="crud_item" class="button-accept" :loading="getModalLoading">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {
  CREATE_ABSENT_PERSONS,
  UPDATE_ABSENT_PERSONS,
  DELETE_ABSENT_PERSONS,
} from "@/store/actions/absent";
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {GET_ALL_WITH_BALANCE, GET_ARCHIVE} from "@/store/actions/flat_service";
import {absentReasonSelect} from "@/utils/icons"
import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'flat_indicator__modal_delete'

export default {
  name: "HWP_Modal_FlatIndicator",
  mixins: [ModalComponentMixin],
  data() {
    return {
      absentReasonSelect,
      navigation: false,
      flat_name: this.item.flat_name,
      person_hash: this.item.person_hash,
      persons: this.item.persons || 0,
      date_start: this.item.date_start || null,
      date_end: this.item.date_end || null,
      absent_reason: this.item.absent_reason || null,
    }
  },
  computed: {
    ...mapGetters({
      indicator_select: 'getFlatIndicatorTypes',
    }),
  },
  methods: {
    ...mapActions({
      fetchFlatServices: GET_ALL_WITH_BALANCE,
      getAccountArchive: GET_ARCHIVE
    }),
    closeModal(payload=null) {
      this.$emit('closeModal', payload)
      this.itemId = this.item.id
      this.flat_name = this.item.flat_name
      this.person_hash = this.item.person_hash
      this.persons = this.item.persons
      this.date_start = this.item.date_start
      this.date_end = this.item.date_end
      this.absent_reason = this.item.absent_reason || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення відсутності осіб: ${this.persons}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Причина відсутності, значення, дата початку та дата закінчення - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        person_hash: this.person_hash,
        absent_reason: this.absent_reason,
        persons: this.persons,
        date_start: this.date_start,
        date_end: this.date_end
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_ABSENT_PERSONS, payload)
            .then(() => {
              this.closeModal("fetch")
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_ABSENT_PERSONS, payload)
            .then(() => {
              this.closeModal("fetch")
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.flat_name = payload.flat_name
              this.person_hash = payload.person_hash
              this.persons = payload.persons
              this.date_end = payload.date_end
              this.date_start = payload.date_start
              this.absent_reason = payload.absent_reason
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_ABSENT_PERSONS, this.itemId)
                      .then(() => {
                        this.closeModal("fetch")
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
}
</script>
